export function saveToken({ data }: MessageEvent) {
  if (
    data.hasOwnProperty('token') &&
    data.hasOwnProperty('emailVerified') &&
    data.hasOwnProperty('uid')
  ) {
    localStorage.setItem('copt-token', JSON.stringify(data));
  } else if (data === 'copt-clear-token') {
    localStorage.removeItem('copt-token');
  }
}

export function getToken() {
  let result;
  const lsValue = localStorage.getItem('copt-token');
  if (lsValue) {
    const token = JSON.parse(lsValue);
    result = token;
  }
  return result;
}
